import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import RichTextBlock from './rich-text-block';
import { parseHTML } from '../../helpers/parseHTML';
import SmartLink from '../global/smart-link';
import gtmEvent from '../../helpers/gtmEvent';
import { set } from 'lodash';

// Props
interface props {
  componentData: {
    url: string
  }
}
const useMutationObserver = (
  ref,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) => {
  React.useEffect(() => {
    if (ref) {
      const observer = new MutationObserver(callback);
      observer.observe(ref, options);
      return () => observer.disconnect();
    }
  }, [callback, options]);
};
export default function FullPageIframe({ componentData }: props) {
  const {
    url,
  } = componentData;
  const [iframeHeight, setIframeHeight] = useState(0);
  const iframe = React.useRef<HTMLIFrameElement>(null);
  const onDomMutation = () => {
    if (typeof document === 'undefined' || typeof window === 'undefined') return;
    if (!iframe) return;
    const nav = document?.querySelector('nav');
    const navMobile = document?.querySelector('.nav-mobile');
    const breadcrumbs = document?.querySelector('.breadcrumbs');
    const footer = document?.querySelector('footer#footer');
    const navAlert = document?.querySelector('.nav-alert.active.mobile');
    let totalNavHeight = (nav?.clientHeight || 0) + (navMobile?.clientHeight || 0) + (breadcrumbs?.clientHeight || 0) + (footer?.clientHeight || 0);
    if (navAlert) {
      totalNavHeight += navAlert?.clientHeight || 0;
    }


    if (totalNavHeight !== iframeHeight) {
      iframe.current!.style.height = `calc(100vh - ${totalNavHeight}px)`;
      setIframeHeight(totalNavHeight);
    }
    setTimeout(() => onDomMutation(), 500);

  };
  if (typeof document !== 'undefined') {
    useMutationObserver(document.querySelector('body'), onDomMutation);
  }


  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('full-page-iframe-body')
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onDomMutation);
    }
    setTimeout(() => onDomMutation(), 500);
  }, [])
  return (
    <div className="full-page-iframe">
      <div className='outer-container'>
        <div className="inner-container">
          <iframe src={url} className="full-page-iframe__iframe" ref={iframe} allow="geolocation microphone camera midi encrypted-media vr" tabIndex={-1}/>
        </div>
      </div>


    </div>

  )
}
